.formBG{
    padding: 40px 0;
    background-color: #f9f4ff;
    display: flex;
    justify-content: center;

    .checkGood{
        background-color: rgba($color: #fff, $alpha: .85);
        position: fixed;
        top: 0;
        right: 0;
        z-index: 99999999;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .imagePhoth{
            height: 300px;
            width: 300px;

            img{
                width: 100%;
            }
        }
    }

    .formStation{

        position: relative;

        .goBackBtn{
            position: fixed;
            top: 155px;
            left: 320px;
            padding: 5px 10px;
            font-size: 21px;
            cursor: pointer;

            color: #6c43d5;

            display: flex;
            align-items: center;

            i{
                margin-top: 6px;
            }

            p{
                font-size: 14px;
                margin-top: 0px;
                font-weight: bold;
                margin-left: 10px;
            }

        }

        margin-top: 100px;
        margin: 100px;
        display: flex;
        flex-direction: column;
        padding: 30px 0px;
        width: 60%;
        border-radius: 20px;
        align-items: center;
        padding-top: 10px;

        .updated{
            margin-bottom: 30px;
            //font-style: italic;
            font-size: 12px;
            font-weight: bold;
            background-color: #6c43d5;
            color: #fff;
            padding: 10px 25px;
            border-radius: 100px;
        }

        .noAuthorization{
            width: 300px;
            height: 620px;

            img{
                width: 100%;
            }
        }

        .formUI{

            margin-top: 70px;

            .formHolder{
                background-color: #fff;
                border-radius: 20px;
                padding: 50px;
                margin-bottom: 30px;

                h3{
                    margin-bottom: 20px;
                }

                .nameH{
                    flex-direction: column;
                    align-items: flex-start;

                    .val2{
                        border-bottom: 1px solid #eee;
                        padding-bottom: 10px;
                        width: 100%;
                        margin-top: 5px;
                    }
                    
                }

                p{
                    font-size: 12px;
                    margin-bottom: 8px;
                    font-weight: bold;
                }
            }

            form {

                label{
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 30px;
                }

                .radioBtn{
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    margin-top: 15px;


                    input[type=radio]{

                        accent-color: rgb(143, 16, 143);
                        height: 18px;
                        width: 18px;

                    }


                    input[type=text]{

                        width: 80%;
                        border: none;
                        border-bottom: 1px solid #dcdcdc;
                        padding-bottom: 15px;
                        outline: none;
                        
                        &:focus{
                            border-bottom: 1px solid rgb(143, 16, 143);
                        }

                        transition: color .4s ease-in-out forwards;

                    }

                    input[type=text]::placeholder{

                        width: 80%;
                        border: none;

                    }


                    .val{
                        font-size: 13px;
                    }

                }

                .submitButtonForm{

                    background-color: rgb(143, 16, 143);
                    width: max-content;
                    padding: 15px 35px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 8px;
                    cursor: pointer;
                    
                }

            }
        }

        .header_title{
            text-align: center;

            p{
                margin-top: 8px;
                color: #777;
            }
        }

    }
}

@media (max-width: 1500px) {

    .formStation{
        width: 80% !important;
        margin: 100px 40px !important;
    }
}

@media (max-width: 768px) {

    .formStation{
        width: 100% !important;
        margin: 80px 20px !important;
    }
}
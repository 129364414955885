.Register_admin{
    height: 100vh;
    flex-direction: row;

    .submit-btn{
        background-color: rgb(63, 0, 145) !important;
    }

    .sidebarDesign{
        height: 100vh;
        width: 40%;
        background-image: url('https://technext.ng/wp-content/uploads/2020/08/LASRIC-Photo-3.jpg');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .contentAdmin{

        width: 60%;
        background-color: #fff;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        form{
            width: 55%;

            .full-type{
                background-color: rgb(252, 249, 255)
            }

            overflow: visible !important;
        }
    }
}
.footer{
    height: 100px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    border-top: 1px solid whitesmoke;
    justify-content: space-between;

    .logo-foot{
        width: 120px;

        img{
            width: 100%;
        }

        
    }

    .links-footer{

        a{
            font-size: 13px;
            color: #333;
            margin: 0 15px;
        }

    }
}

@media (max-width: 425px) {

    .footer{
        flex-direction: column;
        padding: 30px 0;

        .links-footer{
            display: flex;
            flex-direction: column;
            padding: 20px 0;
            padding-bottom: 50px;

            a{
                margin-top: 20px;
                text-align: center;
            }
        }
    }

}

.client_dashboard, .client_council, .client_admin{
    display: none;

}
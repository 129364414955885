.contactPageLanding{

    padding-top: 160px;
    margin: 0 auto;
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 70px;

    .top__header{

        text-align: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        p{
            font-size: 50px;
            font-weight: 500;
            line-height: 58px;
            letter-spacing: -1px;
        }

        .subP{
            color: #777;
            width: 70%;
            line-height: 26px;
        }

    }

    .emailPoint{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin: 60px 0;

        a{
            border: 1px solid #e6e6e6;
            padding: 10px 25px;
            border-radius: 5px;
            color: green;
            border-color: green;
        }
    }

    .socials{
        display: flex;
        gap: 50px;
        margin-top: 0px;

        .social{
            height: 100px;
            width: 100px;
            
            img{
                width: 100%;
                height: 100%;
            }
        }
    }

}

@media(max-width : 768px) {

    .contactPageLanding{

        padding: 0 12%;
        padding-top: 120px;
        padding-bottom: 70px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .top__header{
    
            text-align: center;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
    
            p{
                font-size: 35px;
                font-weight: 500;
                line-height: 45px;
                letter-spacing: -1px;
            }
    
            .subP{
                color: #777;
                width: 100%;
                line-height: 26px;
            }
    
        }
    
        .emailPoint{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin: 60px 0;
    
            a{
                border: 1px solid #e6e6e6;
                padding: 10px 25px;
                border-radius: 5px;
                color: green;
                border-color: green;
            }
        }
    
        .socials{
            display: flex;
            gap: 50px;
            margin-top: 0px;
    
            .social{
                height: 70px;
                width: 70px;
                
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    
    }

}

@media(max-width : 425px) {

    .contactPageLanding{

        padding: 0 5%;
        padding-top: 120px;
        padding-bottom: 70px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .top__header{
    
            text-align: center;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
    
            p{
                font-size: 26px;
                font-weight: 500;
                line-height: 35px;
                letter-spacing: -1px;
            }
    
            .subP{
                color: #777;
                width: 100%;
                line-height: 24px;
                font-size: 14px;
            }
    
        }
    
        .emailPoint{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin: 40px 0;
    
            a{
                border: 1px solid #e6e6e6;
                padding: 10px 25px;
                border-radius: 5px;
                color: green;
                border-color: green;
            }
        }
    
        .socials{
            display: flex;
            gap: 30px;
            margin-top: 30px;
    
            .social{
                height: 50px;
                width: 50px;
                
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    
    }

}
.council_heading{
    display: flex;
    padding: 10px 12%;
    height: 80px;
    background-color: #fff;
    border-bottom: 1px solid whitesmoke;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 9999999999;
    

    .changePassword{
        background-color: #003ad6;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 999;

        .closePassword{
            position: absolute;
            top: 5%;
            right: 12%;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
        }

        .showPass{
            font-size: 12px;
            margin-right: 20px;
            cursor: pointer;

            &:hover{
                color: #003ad6;
            }
        }
    }


    .logo-lasric{
        width: 130px;

        img{
            width: 100%;
        }
    }

    .main-menu{
        display: flex;
        align-items: center;
        
        a{
            display: flex;
            margin: 0 20px;
            font-size: 12px;
            align-items: center;

            .number{
                font-weight: bold;
                margin-left: 5px;
            }

            &:first-child{

                .number {
                    color: #003ad6;
                }

            }

            &:nth-child(2){

                .number {
                    color: #ff9d00;
                }
                
            }

            &:nth-child(3){

                .number {
                    color: #00c9aa;
                }
                
            }

            &:last-child{

                .number {
                    color: #7600ff;
                }
                
            }
        }
    }

    .closingPart{
        display: flex;
        align-items: center;
        font-size: 12px;
        position: relative;

        .callupsID, .appsLink{
            font-weight: bold;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            
            .fi{
                margin-left: 10px;
                margin-top: 4px;
            }
        }

        .popOut{
            padding: 10px 30px;
            background-color: #fff;
            box-shadow: 3px 5px 20px rgba($color: #000000, $alpha: 0.03);
            border: 1px solid whitesmoke;
            position: absolute;
            top: 12px;
            border-radius: 8px;
            right: 0px;
            width: max-content;
            font-weight: normal;

            li{
                list-style: none;
                margin: 15px 0;
            }
        }

        .shortLine{
            height: 20px;
            width: 1px;
            background-color: rgba($color: #000000, $alpha: 0.2);
            margin: 0 30px;
        }

        .accounts{
            padding: 15px 25px;
            background-color: crimson;
            color: #fff;
            border-radius: 5px;
            font-weight: bold;
            cursor: pointer;
        }
    }

}

.nameSpace{

    padding: 120px 12%;
    padding-bottom: 40px;
    display: flex;
    align-items: center;

    .appCards{
        display: flex;

        .Card{
            height: 100px;
            padding: 15px;
            width: 180px;
            color: #fff;
            background-color: #f60;
            margin-right: 15px;
            border-radius: 5px;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            align-items: center;

            &:first-child{

                background-color: #4d53e0;

            }

            &:last-child{
                background-color: #25dba3;
            }
        }
    }

    h1{
        font-size: 28px;
        margin-top: 2px;
    }
}

.applications-council{
    margin : 0 12%;
    padding-bottom: 100px;
    background-color: #fff;
    // background-color: #93fffa;
    border-radius: 20px;
    padding: 30px;

    .tableHeaders{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tableHead{
            width: 200px;
            font-size: 13px;

            &:nth-child(4){
                width : 100px;
            }

            &:last-child{
                width : 100px;
            }

            &:first-child{
                width: 300px;
            }

            &.avarta{
                display: flex;
                align-items: center;

                .cardMe{
                    margin-right: 20px;
                    font-weight: bold;
                    height: 40px;
                    width: 40px;
                    border-radius: 100%;
                    background-color: teal;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    position: relative;

                    .iconShow{
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        background-color: #003ad6;
                        bottom: 0;
                        overflow: hidden;
                        border-radius: 100%;
                        right: 0;
                        display: none;
                    }
                }
            }
        }

        &.itshead{
            padding-bottom: 10px;
            margin-bottom: 10px;
            padding: 10px 0px;
            padding-bottom: 30px;
            margin: 0 20px;
            border-bottom: 1px solid whitesmoke;
            font-weight: 400;
        }

        &.information{

            border-radius: 5px;
            cursor: pointer;
            padding: 15px 0px;
            transition: all 0.3s ease-in-out;
            border-bottom: 1px solid whitesmoke;
            margin: 15px 20px;
            padding-bottom: 20px;

            &:hover{
                background-color: #f9f9f9;
                box-shadow: none;
            }
        }
    }
}

.no-data-state{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p{
        font-size: 14px;
        font-weight: bold;
    }
}

.gradingApplication{

    margin: 0 12%;
    padding-bottom: 100px;


    .liveScore{
        width: 20%;
        height: 500px;
        background-color: #fff;
        box-shadow: 3px 5px 20px rgba($color: #000000, $alpha: 0.05);
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        position: fixed;
        top: 80px;
        display: none;

        .user-profile, .score{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .user-profile{
            .init{
                width: 100px;
                height: 100px;
                background-color: #000;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 20px;
                color: #fff;
            }

            margin-bottom: 30px;
        }

        .score{
            margin: 10px 0;
            h1{
                font-size: 70px;
            }
        }

        .activity{
            position: absolute;
            bottom: 50px;
            font-size: 14px;
            background-color: rgb(7, 136, 136);
            padding: 20px 40px;
            border-radius: 100px;
            color: #fff;
            font-weight: bold;

        }
  
    }

}

.gradingPart{

    width: 100%;
    margin: 0 auto;
    padding:10px 30px;
    background-color: #fff;

    .bleet{

        .deleteCone{
            background-color: blue !important;
            display: none !important;
        }

    }

    table td {

        vertical-align: top;

        input{


        margin: 0 !important;


        }
    }

    td textarea{
        font-size: 14px;
        line-height: 24px;
        margin: 0px 0 !important;
        padding: 10px 20px !important;
        background-color: #fff;
    }

    td{

        input{
            background-color: #fff;
        }
    }

    textarea{
        font-size: 16px;
        color: #000;
        line-height: 28px;
        padding: 20px;
        border-radius: 8px;
    }

    .line-section{
        opacity: .5;
    }

    .section{
        
        label{
            font-size: 21px;
            color: #000;
        }
    }

    .grade-value{
        border-color: whitesmoke;
        background-color: rgba(0, 214, 143, 0.2);
        background-color: #000;
        padding: 30px;
        border-radius: 8px;

        label{
            color: #fff;
        }

        input{
            color: #fff;
            border: 1px solid rgba($color: #fff, $alpha: 0.3) !important;
            padding: 20px;
            border-radius: 8px
        }
    }
}

.council-wrap{
    padding : 0px 0 !important;
}

.showSheet{
    display: block !important;
}

.action{
    background-color: #f60 !important;
    color: #fff;
    font-weight: bold;
}

.realtimeScore{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 30px;
    position: fixed;
    background-color: #fff7ea;
    background-color: #000;
    color: #fff;
    bottom: 0;
    left: 0;
    padding: 10px 12%;

    .submitApplication{
        margin: 0;
        padding: 15px 20px;
        border-radius: 8px;
        background-color: #ff9d00;

        &.emppty{
            background-color: rgb(27, 27, 27);
            color: #777;
        }
    }

    
}


.loaderScreen{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba($color: #fff, $alpha: 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sub-section a{
    display: block;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;

    &:hover{
        color: #00c9aa;
    }
}

.sub-section input{
    margin-top: 15px !important;
}

.councilBGArea{
    background-color: rgb(250, 250, 250);
}

.gradeHere{
    width: 30%;
    height: 100vh;
    background-color: #00c9aa;
    display: none;
}



.viewVideo{
    background-color: #7600ff;
    color: #fff;
    padding: 18px 20px;
    width: max-content;
    font-size: 12px;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    
}
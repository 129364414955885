.sideMenuBar{
    height: 100vh;
    width: 270px;
    position: fixed;
    background-color: #fff;
    box-shadow: 1px 3px 20px rgba($color: #000000, $alpha: .03);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 99999999;

    .menuItem{
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        color: #333;
        cursor: pointer;
        padding-left: 30px;
        width: 95%;
        

        .menuName{
            font-size: 12px;
            text-align: center;
            margin-top: -5px;
            margin-left: 15px;
        }

        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius:10px;
            color: #333;
        }

        &.active{

            color: #fff;
            background-color: #25dba3;
            background-color: rgb(132, 57, 230);
            //background-color: rgb(0, 43, 235);
            //background-color: rgb(255, 153, 0);
            padding: 15px 30px;
            border-radius: 0 30px 30px 0;
            font-weight: bold;

            .icon{
                color: #fff;
            }
            
        }
    }

    
    .management{

        display: flex;
        padding: 15px;
        align-items: center;
        height: 120px;
        margin-bottom: 10px;
        justify-content: space-between;
        
        .imageMan{

            width: 50px;
            height: 50px;
            border-radius: 100%;
            overflow: hidden;

            img{
                width: 100%;
            }
            
        }

        .titleMan{

            width: max-content;
            margin-left: 10px;
            
            h4{
                font-size: 18px;
            }

            p{
                font-size: 14px;
                color: #333;
            }
        }

    }

}

.headerAdmin{

    padding-left: 300px;
    background-color: #fff;
    //background-color: rgb(253, 250, 255);
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color: #000000, $alpha: .05);
    padding-bottom: 20px;
    padding-top: 40px;
    padding-right: 30px;
    position: fixed;
    width: 100%;
    z-index: 9;

    .lasric_logo{

        width: 150px;

        img{
            width: 100%;
        }
    }

    .accountArea{
        font-size: 12px;
        display: flex;
        align-items: center;

        .firstname{
            font-weight: bold;
            margin-left: 2px;
        }

        .initialsAdmin{
            margin-left: 15px;
            margin-right: 8px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(195, 167, 231);
            border-radius: 100%;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
        }

        .icon{
            cursor: pointer;
            font-size: 15px;
        }
    }

}

.pagesComponent{
    width: calc(100vw - 270px);
    margin-left: 270px;
    padding: 30px;
    padding-top: 135px;
    //background-color: rgb(253, 250, 255);
}


// overview pages

.overviewAdmin{

    display: flex;
    flex-direction:column;
    align-items: flex-start;

    .dashboard_cards{

        display: flex;

        .longCards{

            display: flex;

            .card_stat{

                width: 170px;
                height: 270px;
                background-color: rgb(132, 57, 230);
                color: #fff;
                font-size: 12px;
                padding: 20px;
                padding-bottom: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-position: top;
                background-repeat: no-repeat;
                background-position-y: -200px;
                border-radius: 20px;
                margin: 0 5px;
                
                .iconFit{
                    width: 50px;
                    height: 50px;
                    border: 3px solid #fff;
                    border-radius: 100%;
                    overflow: hidden;
                    background-color: #fff;

                    img{
                        width: 100%;
                    }
                }

                &:first-child{
                    margin-left:0 ;
                }

                &:nth-child(2){
                    background-color: #01a2ff
                }

                &:nth-child(3){
                    background-color: #4d74e0;
                }

                .stat_number{
                    font-size: 50px;
                    font-weight: bold;
                }

                .title{
                    font-size: 14px;
                    margin-top: -5px;
                }
                

            }
        }

        .shortCards{

            .layerCard{
                display: flex;
                margin-left: 5px;
            }            

            .layerCard2{
                margin-top: 15px;
            }


            .card_stat{

                width: 170px;
                height: 127px;
                background-color: #fff;
                padding: 0px;
                font-size: 13px;
                border-radius: 20px;
                margin: 0 7.5px;
                box-shadow: 3px 5px 30px rgba($color: #000000, $alpha: .03);
                border: 1px solid whitesmoke;
                padding: 20px;
                position: relative;

                .detailedStat{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: 100%;

                    .iconFit{
                        width: 40px;
                        height: 40px;
                        border: 3px solid #fff;
                        border-radius: 100%;
                        overflow: hidden;
                        position: absolute;
                        right: 20px;
                        top: 20px;
    
                        img{
                            width: 100%;
                        }
                    }
                }

                .title{
                    font-size: 12px;
                }

                .stat_number{
                    font-weight: bold;
                    font-size: 16px;
                }

            }

        }

    }

    .applicationAdmin{

        background-color: #f9f9f9;
        background-color: #fff;
        box-shadow: 3px 5px 30px rgba($color: #000000, $alpha: .03);
        border: 1px solid whitesmoke;
        margin : 20px 0;
        padding: 20px 5px;
        width: 100%;

        .previewThisApp{
            background-color: rgba($color: #000, $alpha: .1);
            position: fixed;
            top: 0;
            right: 0;
            width: calc(100% - 270px);
            height: 100vh;
            z-index: 99;
            display: flex;
            justify-content: flex-end;
            overflow-y: scroll;

            .previewAppInfo{
                height: max-content;
                background-color: #fff;
                width: 50%;
                padding: 50px 5%;
                position: relative;
                animation: popInModal .3s ease-in-out;

                .councilgraded{
                    background-color: #fff;
                    box-shadow: 0 3px 30px rgba($color: #000000, $alpha: .04);
                    border: 1px solid whitesmoke;
                    padding: 20px 20px;
                    border-radius: 10px;
                    padding-bottom: 0px;
                    margin-bottom: 30px;


                    .title{
                        font-size: 14px;
                        margin-bottom: 40px;
                    }
                }

                .closePreview{
                    position: absolute;
                    top: 0;
                    margin: 20px;
                    left : 0;
                    background-color: #01a2ff;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    border-radius: 100%;
                    background-color: crimson;
                    cursor: pointer;
                    font-size: 8px;
                }

                .viewApp{
                    padding: 18px 23px;
                    border-radius: 5px;
                    font-size: 12px;
                    text-align: center;
                    color: #fff;
                    font-weight: bold;
                    background-color: rgb(132, 57, 230);
                    cursor: pointer;
                    width: max-content;
                    margin: 0 auto;
                }

                .photoName{

                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .init{

                        width: 60px;
                        height: 60px;
                        background-color: #000000;
                        border-radius: 100%;
                        color: #fff;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 21px;


                    }

                    .fullname{
                        font-size: 21px;
                        font-weight: bold;
                        margin-top: 20px;
                    }

                }

                .userDetails{
                    background-color: #fff;
                    box-shadow: 0 3px 30px rgba($color: #000000, $alpha: .04);
                    border: 1px solid whitesmoke;
                    padding: 5px 20px;
                    border-radius: 10px;
                    margin : 50px 0;
                    padding-bottom: 0px;
                    margin-bottom: 20px;
                }

                .coreDetail{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    margin: 30px 0;
                    border-bottom: 1px solid rgb(231, 231, 231);
                    padding-bottom: 20px;

                    .councilImage{
                        width: 40px;
                        height: 40px;
                        background-color: #999;
                        overflow: hidden;
                        margin-right: 15px;
                        border-radius: 100%;

                        img{
                            width: 100%;
                        }
                    }

                    &:last-child{
                        border-bottom: none;
                        margin-bottom: 10px;
                    }

                    .icon{
                        display: flex;
                        align-items: center;
                        color: #333;
                    }

                    .fi{
                        margin-right: 10px;
                        // color: #000;
                        // width: 40px;
                        // height: 40px;
                        // background-color: rgb(195, 167, 231);
                        // display: flex;
                        // border-radius: 100%;
                        // align-items: center;
                        // justify-content: center;
                        // color: #fff;
                    }
                }
            }
        }

        .tableHeaders{
            
            border-color: rgb(238, 238, 238);

            &:last-child{
                border: none;
            }

            .avarta{

                .cardMe{
                    background-color: rgb(195, 167, 231);
                }
            }

            &:hover{
                background-color: #f9f9f9;
            }
        }
    }

    .loadingData{

        background-color: rgba($color: #fff, $alpha: .8);
        height: 100vh;
        width: calc(100vw - 270px);
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
    }


}

@keyframes popInModal {

    from {

        margin-right: -43%;

    }

    to {
        
        margin-right: 0;
    }
}

.applicationsPage{

    .tableInfo{

        border-bottom: 1px solid whitesmoke;
        margin-bottom: 30px;
        width: 100%;
        
    }
    

   .filters{

        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
    
        li{
            list-style: none;
            font-size: 12px;
            margin: 0 20px;
            padding-bottom: 20px;

            &:first-child{
                margin-left: 0;
            }

            &.active{
                border-bottom: 1px solid rgb(132, 57, 230);
            }
        }

   }
}

.messup{
    width: 100%;
    margin: 0;
    padding-left: 0;

    .itshead{
        padding: 0;
        margin: 0 !important;
    }
}

.headCouncil{
    font-size: 14px;
    width: calc(100% - 270px);
    background-color: rgb(33, 28, 36);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 30px rgba($color: #000, $alpha: .03);
    padding: 12px 30px;
    position: fixed;
    margin: -30px;
    margin-top: -75px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .createNew{
        font-size: 11px;
        background-color: #25dba3;
        padding: 15px 18px;
        color: #fff;
        border-radius: 8px;
        font-weight: bold;
        cursor: pointer;

    }

    .headerTags{
        font-size: 13px;
        font-weight: bold;
    }
}

.councilMembers{

    padding-top: 0px;

}

.tableHead{

    .councilImageSide{
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border-radius: 50%;

        img{
            width: 100%;
        }
    }
}

.councilMem{
    padding: 15px 0 !important;
    margin: 15px 0 !important;    
}

.previewCouncil{

    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba($color: #000, $alpha: .3);
    height: 100vh;
    width: calc(100% - 270px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    padding-top: 350px;
    z-index: 99;

    .successModal{
        z-index: 9999;
        position: fixed;
        top: 0%;
        height: 100vh;
        width: 81.5%;
        background-color: rgba($color: #fff, $alpha: .9);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .closeModalCouncil{

        background-color: crimson;
        width: 40px;
        height: 40px;
        position: absolute;
        z-index: 999;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        right: 200px;

        .fi{
            margin-top: 5px;
        }

    }

    .button-submit{
        background-color: #000;
        margin-top: 70px;
        border-radius: 100px;
        padding: 20px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        width: 80%;
        position: absolute;
        bottom: 40px;
    }
    
    .deleteCouncilMember{

        font-size: 12px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 0px;
        border-top: 1px dashed  rgba($color: #000000, $alpha: .2);
        padding-top: 30px;
        color: crimson;
        cursor: pointer;

        .fi{
            margin-right: 10px;
            width: 30px;
            height: 30px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: crimson;
            border-radius: 100%;
            font-size: 14px;
        }
    }

    .councilInformation{

        width: 70%;
        height: max-content;
        position: fixed;
        top: 0px;
        left: 270px;
        display: flex;

        .rdw-editor-wrapper{
            font-size: 12px;
        }

        .profileImageContainer{
            display: flex;
            position: relative;
            align-items: center;
            background-color: #fff;
            padding: 33px 30px;
            width: 30%;
            border-right: 1px solid whitesmoke;
            overflow: hidden;
            flex-direction: column;
            align-items: center;
            order: 2;
            height: 520px;

            .email_prev{
                font-size: 13px !important;
                color: #444;
                text-decoration: underline;
                margin-top: 20px;
            }

            .buttonReset{
                background-color: rgb(132, 57, 230);
                color: #fff;
                font-size: 11px;
                padding: 15px 20px;
                border-radius: 100px;
                font-weight: bold;
                margin-top: 50px;
            }

            .toggleArea{

                font-size: 11px;
                display: flex;
                border-radius: 100px;
                right: 0;
                color: #333;
                align-items: center;
                font-weight: bold;
                margin-top: 19px;
            }

            .toggleCouncilType{

                margin: 0 15px;
                font-size: 11px;
                height: 20px;
                background-color: #e9e9e9;
                color: #fff;
                border-radius: 100px;
                width: 35px;
                cursor: pointer;
                position: relative;
                
                .togglePin{
                    height: 20px;
                    width: 20px;
                    background-color: rgb(132, 57, 230);
                    border-radius: 100%;
                    position: absolute;
                    transition: all .3s ease-in-out;

                    &.switch{
                        right: 0;
                    }
                }

                .fi{
                    margin-right: 5px;
                }
            }

        }

        .formAction{
            
            overflow-y: scroll;
            height: 100vh;
            width: 70%;
            padding: 0 30px;
            padding-top: 5px;
            padding-bottom: 28px;
            background-color: #fff;
            box-shadow: 10px 0 30px rgba($color: #000, $alpha: .03);
            z-index: 9;

        }


        .profileImage{
            width: 120px;
            height: 120px;
            background-color: #fff;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
            margin-top: 50px;

            &:hover{
                img{
                    opacity: .4;
                }
            }

            img{
                width: 100%;
            }
        }

        .councilName{
            font-size: 14px;
            margin-top: 30px;

            .lastname-council, .firstname-council{
                font-size: 18px;
                letter-spacing: -.5px;
                text-align: center;
                line-height: 23px;
                margin-top: 30px;
            }
        }

        .gradeTrack{
            font-size: 12px;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .05);
            padding-bottom: 15px;
            margin-bottom: 40px;
            font-weight: bold;
            display: flex;
            align-items: center;

            .fi{
                margin-right: 10px;
                margin-top: 3px;
            }
        }

        .editorDraft{
            margin-top: -20px;

            .rdw-editor-main{

                background-color: #f9f9f9;
                padding: 10px 20px;
                min-height: 200px;

            }
        }

        .tap{
            position: absolute;
            margin-top: 220px;
            font-size: 11px;
            margin-left: -7px;
            color: #777;
        }

        form{
            margin-top: 40px;

            .hide{
                display: none !important;
            }

            .inputForm{

                .uploadImage{
                    background-color: #333;
                    width: max-content;
                    padding: 15px 25px;
                    font-size: 12px;
                }
                
                .input-label{
                    font-size: 12px;
                    margin-left: 15px;
                }
            }



            input{
                width : 100%;
                padding: 15px 20px;
                margin-bottom: 15px;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                outline: none ;
                font-size: 11px;

                &::placeholder{
                    font-size: 11px;
                    color: #999;
                }
            }

            .select-box{

                margin-top: 25px;
                margin-bottom: 40px;

                .checkboxx{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    p{
                        font-size: 12px;
                    }

                    input{
                        width: max-content;
                        margin: 0;
                    }
                }
            }
        }
    }

}

.tableInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterTrack{

    margin-top: 45px;
    position: absolute;
    right: 28px;
    display: flex;
    align-items: center;

    .btn_download{
        margin-left: 15px;
        font-size: 12px;
        font-weight: bold;
        color : #fff;
        padding: 14px 20px;
        border-radius: 100px;
        background-color: #25dba3;
        margin-right: 5px;
        cursor: pointer;
    }
    
    select{

        padding: 14px 10px;
        font-size: 12px;
        outline: none;
        border-radius: 100px;
        border: 1px solid whitesmoke;
        background-color: #fff;
        box-shadow: 0px 3px 30px rgba($color: #000, $alpha: .03);
        cursor: pointer;

    }

}

.dropdownAuth{
    background-color: rgb(220, 20, 143);
    color: #fff;
    font-weight: bold;
    padding: 15px 25px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.messageBox{

    display: flex;
    margin-top: 30px;
    background-color: #fff;
    box-shadow: 3px 5px 30px rgba($color: #000, $alpha: .05);
    height: 570px;
    border-radius: 15px;

    .messageOutbox, .message_send{

        .email_listing{
            overflow: auto;
            height: 430px;
        }

        .headingTap{
            padding: 5px;
            font-size: 14px;
            font-weight: 500;
            border-bottom: 1px solid whitesmoke;
            padding-bottom: 15px;
            display: flex;
            align-items: center;

            .fi{
                margin-right: 10px;
                margin-top: 2px;
            }
        }

        .stats_counter{
            font-size: 10px;
            background-color: #f7eeff;
            width: max-content;
            border-radius: 100px;
            padding: 10px 15px;
            margin: 20px auto;
            margin-bottom: 10px;
        }

        .email{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 25px;
            border-bottom: 1px solid whitesmoke;
            padding-bottom: 25px;
            cursor: pointer;

            &:last-child{
                border: none;
            }
            
            .nameInit{
                width: 35px;
                height: 35px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #d0ede7;
                font-size: 13px;
                color: #007e65;
                font-weight: bold;
            }

            &:nth-child(2n) {
                .nameInit{
                    background-color: #e2d0ed;
                    color: #5a007e;
                }
            }

            .emailBar{
                width: calc(100% - 55px);

                .subject_email{
                    font-size: 12px;
                    font-weight: bold;
                    color: #444;
                }

                .body_email{
                    font-size: 12px;
                    color: #777;
                }

            }
            
        }
    }

    .message_send, .messageOutbox{
        padding: 20px 0 20px 20px;
        width: 49%;
    }

    .message_send{

        .sendNewMessage{

            margin-top: 20px;

            .recipient{
                width: 100%;

                select{
                    width: 100%;
                    padding: 10px 15px;
                    border-radius: 5px;
                    border: 1px solid whitesmoke;
                    outline: none;
                    font-size: 12px;

                }
            }

            .subject{
                margin: 10px 0;

                input{
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid whitesmoke;
                    outline: none;
                    padding: 10px 15px;
                    font-size: 12px;
                    
                }
            }

            .message_body{

                .rdw-editor-main{
                    background-color: #f9f9f9;
                    height: 270px;
                    overflow-y: auto;
                }

                .DraftEditor-root {
                    padding: 0 20px;
                    font-size: 12px;
                  }
            }

            .button_submit{
                background-color: #fff;
                font-size: 12px;
                padding: 15px 15px;
                padding-right: 20px;
                margin-top: 10px;
                width: max-content;
                border-radius: 5px;
                display: flex;
                align-items: center;
                box-shadow: 3px 5px 30px rgba($color: #000, $alpha: .04);
                border: 1px solid whitesmoke;
                cursor: pointer;
                transition: all .1s ease-in-out;

                &:hover{
                    background-color: #f9f9f9;
                    box-shadow: none;
                }

                .imageIcon{
                    width: 20px;
                    height: 20px;
                    overflow: hidden;
                    margin-right: 10px;

                    img{
                        width: 100%;
                    }
                }
            }
        }
        
    }

}

.messagingInbox{

    .headingMessage{

        h1{
            font-size: 24px;
        }

        p{
            font-size: 14px;
            color: #777;
        }
        
    }
}





//Admin Awardees

.awardeesArea{

    .downloadPin{
        background-color: red;
        position: absolute;
        z-index: 999999;
    }

    .loadingScreen{
        background-color: #fff;
        position: fixed;
        z-index: 999;
        width: 83%;
        right: 0;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .load{
            width: 200px;
            height: 200px;
            margin-top: -350px;
            margin-left: -100px;

            img{
                width: 100%;
            }
        }
    }

    .absolute_dezz{

        position: fixed;
        height: 100vh;
        width: 100%;
        background-color: rgba($color: #000, $alpha: .3);
        left: 0;
        top: 0;
        z-index: 999999999;
        display: flex;
        justify-content: right;

    }

    .list_awardees_here{

        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }
       

        .addAwardeesP{
            background-color: #fff;
            width: 82%;
            height: 100vh;
            padding: 50px;
            overflow-y: scroll;

            &.awardee_real{
                width: 100%;
                padding: 0;
                margin-top: -50px;
            }

            .checkGood{
                background-color: rgba($color: #fff, $alpha: .85);
                position: absolute;
                top: 0;
                right: 0;
                z-index: 99999999;
                width: calc(82%);
                height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;

                .imagePhoth{
                    height: 300px;
                    width: 300px;

                    img{
                        width: 100%;
                    }
                }
            }
            

            .highlight{

                position: relative;

                .topic{
                    margin-bottom: 50px;
                    position: fixed;
                    width: 82%;
                    top: 0;
                    right: 0;
                    background-color: #fff;
                    padding: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h1{
                        font-weight: 300;
                        margin-bottom: 10px;
                    }

                    p{
                        font-size: 15px;
                        color: #777;
                    }

                    .pushToAwardeeSection{

                        padding: 15px 20px;
                        font-size: 13px;
                        background-color: #1db889;
                        width: max-content;
                        color: #fff;
                        font-weight: bold;
                        border-radius: 8px;
                        cursor: pointer;

                        &.cancel{
                            border: 1px solid crimson;
                            background-color: #fff;
                            color: crimson;
                        }

                    }

                    .control{
                        display: flex;
                        column-gap: 20px;
                    }
                }

                .tableHeader_hl{
    
                    display: flex;
                    align-items: center;

                    &.headingTop{


                        //border-bottom: 1px solid rgba($color: #000, $alpha: .1);
                        padding: 25px 20px;
                        background-color: whitesmoke;
                        margin-top: 145px;


                    }
    
                    .initials_x{
                        width: 50px;
                    }
    
                    .heading_awardee{
                        width: 250px;
                        font-size: 13px;
                        font-weight: bold;
                        margin-right: 50px;
    
                        &.fullname{
                            text-transform: capitalize;
                            width: 350px;
                        }

                        &.comp{
                                
                            width: 250px !important;
                            margin-right: 100px;

                        }

                        &.tiktok{
                            max-width: 15px;
                            max-height: 15px;
                            margin-right: 20px;
                        }
                    }

                    &.list_awardee{

                        border-bottom: 1px solid rgba($color: #000, $alpha: .1);
                        padding: 25px 20px;
                        cursor: pointer;

                        &:hover{
                            background-color: #f9f9f9;
                        }

                        .heading_awardee{
                            width: 250px;
                            font-size: 13px;
                            font-weight: normal;
                            margin-right: 50px;

                            &.btnA{

                                .view{
                                    background-color: #000;
                                    padding: 10px 15px;
                                    border-radius: 8px;
                                    width: max-content;
                                    color: #fff;
                                    font-weight: bold;
                                    font-size: 12px;
                                }
                            }
        
                            &.fullname{
                                text-transform: capitalize !important;
                                display: flex;
                                width: 350px;
                                align-items: center;

                                .initA{
                                    margin-right: 20px;
                                    background-color:#4d74e0;
                                    color: #fff;
                                    font-weight: bold;
                                    height: 40px;
                                    width: 40px;
                                    border-radius: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    pointer-events : none;
                                }
                            }

                            &.comp{
                                
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 250px !important;
                                margin-right: 100px;

                            }
    
                            &.tiktok{
                                max-width: 15px;
                                max-height: 15px;
                                margin-right: 20px;
                            }
                        }

                    }
    
                }
            }

    }

    .header_awardees{

        display: flex;
        align-items: center;
        position: fixed;
        width: calc(83% + 20px);
        justify-content: space-between;
        background-color: #fff;
        z-index: 9999;
        right: 0;
        padding: 30px 30px;
        margin-top: -30px;
       
        .addAwardee{
            font-size: 12px;
            padding: 15px 20px;
            background-color: #1dbb8c;
            cursor: pointer;
            border-radius: 8px;
            color: #fff;
            font-weight: bold;
            color: #fff !important;

            a{
                color: #fff;
            }

            &.downloadX{

                background-color: #4d74e0;

            }
        }

        .buttonsZone{
            display: flex;
            column-gap: 10px;
            color: #fff;
        }

    }

    .awardeeList{

        display: flex;
        align-items: center;
        justify-content: center;
        height: 600px;

        .emptyState{

            background-color: #fff;
            border: 1px solid #eee;
            min-width: 500px;
            width: 800px;
            border-radius: 20px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 400px;
            font-size: 13px;
            overflow: hidden;


            .lottieArr{
                width: 300px;
                overflow: hidden;
                height: 200px;
                margin-top: -30px;

                img{
                    width: 100%;
                }
            }

            .message{
                margin-top: 0px;
            }


        }

    }


}
.councilFrame{

    margin-top: 150px;

    .headerTags{

        display: flex;
        flex-direction: column;
        align-items: center;

        h1{
            font-size: 45px;
        }
        
        p{
            font-size: 14px;
            width: 30%;
            text-align: center;
            margin-top: 10px;
            line-height: 26px;
        }
    }

    .images{

        padding: 0 12%;
        margin: 80px 0;
        display: flex;
        flex-wrap: wrap;

        .councilImageHolder{

            margin-right: 15px;
            margin-bottom: 45px;
            width: 23.5%;
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            &:last-child{
                margin-right: 0;
            }



            .imageContainer{

                overflow: hidden;
                height: 300px;
                width: 100%;
                margin-bottom: 20px;
                background-size: cover;
                background-position: top;
                filter: grayscale(100%);
                background-color: red;
                display: flex;
                align-items: center;

                img{
                    width: 100%;
                }

                
            }

            &:hover{

                .imageContainer{

                    filter: none;
                }


            }

            .councilMemberName{

                font-size: 15px;
                font-weight: bold;

            }

            .councilJob{
                font-size: 12px;
                width: 80%;
                text-align: center;
                margin-top: 8px;
            }

        }
    }

}

@media (max-width: 1024px) {

    .councilFrame{

        margin-top: 150px;
    
        .headerTags{
    
            display: flex;
            flex-direction: column;
            align-items: center;
    
            h1{
                font-size: 35px;
            }
            
            p{
                font-size: 13px;
                width: 40%;
                text-align: center;
                margin-top: 10px;
                line-height: 21px;
            }
        }
    
        .images{
    
            padding: 0 12%;
            margin: 50px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
    
            .councilImageHolder{
    
                margin-right: 10px;
                margin-bottom: 45px;
                width: 30%;
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
    
                .imageContainer{
    
                    overflow: hidden;
                    height: 300px;
                    width: 100%;
                    margin-bottom: 20px;
                    background-size: cover;
                    background-position: top;
                    filter: grayscale(100%);
    
                    
                }
    
                &:hover{
    
                    .imageContainer{
    
                        filter: none;
                    }
    
    
                }
    
                .councilMemberName{
    
                    font-size: 15px;
                    font-weight: bold;
    
                }
    
                .councilJob{
                    font-size: 12px;
                    width: 100%;
                    text-align: center;
                    margin-top: 8px;
                }
    
            }
        }
    
    }

}

@media (max-width: 768px) {

    .councilFrame{

        margin-top: 100px;
    
        .headerTags{
    
            display: flex;
            flex-direction: column;
            align-items: center;
    
            h1{
                font-size: 45px;
            }
            
            p{
                font-size: 14px;
                width: 70%;
                text-align: center;
                margin-top: 10px;
                line-height: 26px;
            }
        }
    
        .images{
    
            padding: 0 12%;
            margin: 50px 0;
            display: flex;
            flex-wrap: wrap;
    
            .councilImageHolder{
    
                margin-right: 10px;
                margin-bottom: 45px;
                width: 31%;
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
    
                .imageContainer{
    
                    overflow: hidden;
                    height: 300px;
                    width: 100%;
                    margin-bottom: 20px;
                    background-size: cover;
                    background-position: top;
                    filter: grayscale(100%);
    
                    
                }
    
                &:hover{
    
                    .imageContainer{
    
                        filter: none;
                    }
    
    
                }
    
                .councilMemberName{
    
                    font-size: 15px;
                    font-weight: bold;
    
                }
    
                .councilJob{
                    font-size: 12px;
                    width: 80%;
                    text-align: center;
                    margin-top: 8px;
                }
    
            }
        }
    
    }

}

@media (max-width: 600px) {

    .councilFrame{

        margin-top: 100px;
    
        .headerTags{
    
            display: flex;
            flex-direction: column;
            align-items: center;
    
            h1{
                font-size: 45px;
            }
            
            p{
                font-size: 14px;
                width: 70%;
                text-align: center;
                margin-top: 10px;
                line-height: 26px;
            }
        }
    
        .images{
    
            padding: 0 12%;
            margin: 50px 0;
            display: flex;
            flex-wrap: wrap;
    
            .councilImageHolder{
    
                margin-right: 10px;
                margin-bottom: 45px;
                width: 47%;
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
    
                .imageContainer{
    
                    overflow: hidden;
                    height: 300px;
                    width: 100%;
                    margin-bottom: 20px;
                    background-size: cover;
                    background-position: top;
                    filter: grayscale(100%);
    
                    
                }
    
                &:hover{
    
                    .imageContainer{
    
                        filter: none;
                    }
    
    
                }
    
                .councilMemberName{
    
                    font-size: 15px;
                    font-weight: bold;
    
                }
    
                .councilJob{
                    font-size: 12px;
                    width: 80%;
                    text-align: center;
                    margin-top: 8px;
                }
    
            }
        }
    
    }

}

@media (max-width: 500px) {

    .councilFrame{

        margin-top: 100px;
    
        .headerTags{
    
            display: flex;
            flex-direction: column;
            align-items: center;
    
            h1{
                font-size: 35px;
            }
            
            p{
                font-size: 13px;
                width: 70%;
                text-align: center;
                margin-top: 10px;
                line-height: 21px;
            }
        }
    
        .images{
    
            padding: 0 12%;
            margin: 50px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
    
            .councilImageHolder{
    
                margin-right: 10px;
                margin-bottom: 45px;
                width: 80%;
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
    
                .imageContainer{
    
                    overflow: hidden;
                    height: 300px;
                    width: 100%;
                    margin-bottom: 20px;
                    background-size: cover;
                    background-position: top;
                    filter: grayscale(100%);
    
                    
                }
    
                &:hover{
    
                    .imageContainer{
    
                        filter: none;
                    }
    
    
                }
    
                .councilMemberName{
    
                    font-size: 15px;
                    font-weight: bold;
    
                }
    
                .councilJob{
                    font-size: 12px;
                    width: 100%;
                    text-align: center;
                    margin-top: 8px;
                }
    
            }
        }
    
    }

}

@media (max-width: 425px) {

    .councilFrame{

        margin-top: 100px;
    
        .headerTags{
    
            display: flex;
            flex-direction: column;
            align-items: center;
    
            h1{
                font-size: 25px;
            }
            
            p{
                font-size: 12px;
                width: 70%;
                text-align: center;
                margin-top: 10px;
                line-height: 21px;
            }
        }
    
        .images{
    
            padding: 0 12%;
            margin: 50px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
    
            .councilImageHolder{
    
                margin-right: 10px;
                margin-bottom: 45px;
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
    
                .imageContainer{
    
                    overflow: hidden;
                    height: 370px;
                    width: 100%;
                    margin-bottom: 20px;
                    background-size: cover;
                    background-position: top;
                    filter: grayscale(0%);
    
                    
                }
    
                &:hover{
    
                    .imageContainer{
    
                        filter: none;
                    }
    
    
                }
    
                .councilMemberName{
    
                    font-size: 15px;
                    font-weight: bold;
    
                }
    
                .councilJob{
                    font-size: 12px;
                    width: 100%;
                    text-align: center;
                    margin-top: 8px;
                }
    
            }
        }
    
    }

}

@media (max-width: 375px) {

    .councilFrame{

        margin-top: 100px;
    
        .headerTags{
    
            display: flex;
            flex-direction: column;
            align-items: center;
    
            h1{
                font-size: 25px;
            }
            
            p{
                font-size: 12px;
                width: 70%;
                text-align: center;
                margin-top: 10px;
                line-height: 21px;
            }
        }
    
        .images{
    
            padding: 0 4%;
            margin: 50px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
    
            .councilImageHolder{
    
                margin-right: 10px;
                margin-bottom: 45px;
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
    
                .imageContainer{
    
                    overflow: hidden;
                    height: 370px;
                    width: 100%;
                    margin-bottom: 20px;
                    background-size: cover;
                    background-position: top;
                    filter: grayscale(0%);
    
                    
                }
    
                &:hover{
    
                    .imageContainer{
    
                        filter: none;
                    }
    
    
                }
    
                .councilMemberName{
    
                    font-size: 15px;
                    font-weight: bold;
    
                }
    
                .councilJob{
                    font-size: 12px;
                    width: 100%;
                    text-align: center;
                    margin-top: 8px;
                }
    
            }
        }
    
    }

}


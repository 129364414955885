.profile_page{

    margin-top: 30px;
    display: flex;
    position: relative;
    flex-direction: column;

    .cohort1{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .form-profile{

        width: 600px;

        .each_input{

            display: flex;
            align-items: center;
            margin-bottom: 15px;

            label{
                margin: 0;
                font-size: 12px;
                color: #999;
                width: 150px;
            }

            input{
                padding: 15px 10px;
                font-size: 12px;
                border: 1px solid whitesmoke;
                border-radius: 8px;
                outline-color: #6600ea;
                width: 100%;
            }

        }

    }

    .button_cohort{
        padding: 15px 10px;
        font-size: 12px;
        border-radius: 8px;
        margin-top: -15px;
        background-color: #25dba3;
        color: #fff;
        font-weight: bold;
        width: 90px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
    }

}

.cohortJoint{

    .alertSuccess{

        background-color: rgb(225, 250, 242);
        width: max-content;
        position: fixed;
        font-size: 13px;
        border-radius: 3px;
        width: 350px;
        z-index: 9999;
        right: -400px;
        margin-top: -70px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding : 10px;
        padding-right: 20px;
        padding-left: 0px;
        transition: all .6s ease-in-out;

        .sethAnim{
            margin: 0 10px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
        }


    }
}
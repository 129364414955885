.loading-anim{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dash-404{

    padding-top: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .video-container{
        margin-top: -120px;

        &.web-vw{
            display: none;
        }

        .animation{
            width: 100px !important;
            height: 100px !important;
        }
    }

    .topic_header{

        margin-top: -90px;
        font-family: 'Poppins', sans-serif;
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        h1{
            font-size: 38px;
        }

        p{
            font-size: 15px;
            text-align: center;
            margin-top: 15px;
            color: #666;
        }
    }

    .goback{

        display : flex;
        margin-top: 50px;
        font-family: 'Poppins', sans-serif;
        align-items: center;
        justify-content: center;
        color: crimson;
        font-weight: bold;
        font-size: 17px;

        i{
            margin-right: 5px;
        }
    }

}

.preview-vid{
    position: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    z-index: 9999999;
    background-color: rgba($color: #000000, $alpha: .7);
   


    .upload-video{

        border-radius: 10px;
        width: 50%;
        background-color: #fff;
        position: relative;
        padding: 30px 40px;
        overflow: hidden;

        .status-plan{

            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            background-color: rgba($color: #000, $alpha: .9);
            z-index: 99999999;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;



            div{
                margin-top: -30px;
                color: #fff;
                font-family: 'Poppins' sans-serif;
                font-size: 16px;
                width: 50%;
                text-align: center;
            }
        }

       
        .header-tags{

            p{
                margin-top: 5px;
            }

            font-family: 'Poppins' sans-serif !important;   
            margin-bottom: 30px;
        }
        

        .videoHolder{
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            video{
                width: 100%;
                border-radius: 10px;
                height: 300px;
            }

            margin-bottom: 15px;
        }

        .button-group{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .upload-new, .cancel, .ok{
                padding: 15px 25px;
                background-color: sandybrown;
                border-radius: 8px;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                font-size: 11px;
            }

            .action_grp{
                
                display: flex;

                .upload-new{
                    margin-right: 20px;
                    background-color: #000;
                    cursor: pointer;
                }

                .cancel{
                    background-color: crimson;
                    cursor: pointer;
                }
            }

            .ok{
                background-color: #43D57F;
                cursor: pointer;
            }


        }

    }
}

.backbutton{
    background-color: #000000;
    font-size: 26px !important;
    width: 40px;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    color: #fff;
    border-radius: 8px;
}

@media (max-width : 425px){

    .preview-vid{     

        .upload-video{
            width: 95%;
            padding: 10px 15px;
        }

        .status-plan{
            div{
                width: 80% !important;
            }
        }

        .button-group{
            flex-direction: column;

            .upload-new, .cancel, .ok{
                width: 100%;
                margin-bottom: 10px;
                text-align: center;
                padding: 20px !important;
            }

            .action_grp{
                flex-direction: column;
                width: 100%;
            }
        }

        .header-tags{
            font-size: 14px;
            text-align: center;

            h1{
                font-size: 18px;
            }
        }
    }


}


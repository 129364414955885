.header{

    position: fixed;
    width: 100%;
    height: 70px;
    overflow: hidden;
    border-bottom: 1px solid whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 9999;

    padding: 50px 12%;

    .logo{
        width: 150px;

        img{
            width: 100%;
        }
    }

    .menulist{
        font-size: 13px;
        
        a{
            margin: 0 15px;
            color: #333;
        }
    }

    .auth-area{
        display: flex;
        overflow: hidden;
        width: max-content;
        font-size: 13px;
        align-items: center;

        .alert{
            height: 18px;
            width: 18px;
            margin-right: 20px;

            img{
                width: 100%;
            }
        }

        .account{
            display: flex !important;
            align-items: center;
            color: #333;

            .fi{
                display: flex;
                align-items: center;
                margin-left: 5px;
            }

        }

        .line-div{
            width: 1px;
            height: 20px;
            background-color: rgba($color: #000000, $alpha: .3);
            margin: 0 20px;
        }

        .act-btn{
            padding: 13px 25px;
            border-radius: 8px;
            background-color: #43D57F;
            text-align: center;
            color: #fff;
            font-weight: bold;
            font-size: 12px

        }

        

    }

    .menu{

        .menuicon{

            position: absolute;
            top: 0;
            margin: 22px 0;
            right: 12%;

        }


        background-color: #fff;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        padding: 150px 30px;
        font-weight: bold;  

        .menulist{
            flex-direction: column;
            display: flex;

            a{
                margin-bottom: 15px;
                font-size: 24px;
                border-bottom: 1px solid whitesmoke;
                padding-bottom: 15px;
            }
        }

        .line-div{
            display: none;
        }

        .auth-area{
            display: flex;
            align-items: center;
            justify-content: center;

            .account{
                margin-left: 20px;
            }

            .act-btn{
                margin-left: 20px;
            }
        }
    }

    

}

.mobile{
    display: none;
}


@media (max-width: 768px) {

    .header{
        padding: 20px 12%;

    }

    .desktop{

        display: none;

    }

    .mobile{
        display: flex;
    }

}

@media (max-width: 425px) {

    .header{
        padding: 20px 5%;
    }

}

.client_dashboard, .client_council, .client_admin{
    display: none;

}
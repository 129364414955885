@font-face {
  font-family: 'Code Next';
  src: url('./assets/fonts/CodeNext-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/CodeNext-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: poppins;
}

* > a{
  
    text-decoration: none;
    color: #000;

}

body{

  overflow-x: hidden !important;
}

.app{
  overflow-x: hidden;
}
.gradeKey{
    h1, p{
        display: none;
    }
}

.newSeth{

    margin: 0 0%;
    padding-bottom: 100px;
    margin-right: 12%;

    .applicationStem{
        margin-left: 370px;
        width: calc(100% - 350px) !important;
        margin-top: -80px !important;
        margin-right: 12% !important;
    }

    .sections{
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        box-shadow: none !important;

        .sub-section{

            label{
                margin-bottom: 0;
                font-weight: bold;
                font-size: 13px;
            }

            input, textarea{
                font-size: 14px;
            }
        }
    }


    .liveScore{
        width: 350px;
        height: 400px;
        background-color: #fff;
        box-shadow: none;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0px;
        position: fixed;
        top: 80px;
        display: flex;
        left: 0;
        border-bottom-right-radius: 10px;

        .user-profile, .score{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .user-profile{
            .init{
                width: 100px;
                height: 100px;
                background-color: #000;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 20px;
                color: #fff;
            }

            margin-bottom: 30px;
        }

        .score{
            margin: 10px 0;
            h1{
                font-size: 70px;
            }
        }

        .activity{
            position: absolute;
            bottom: 50px;
            font-size: 14px;
            background-color: rgb(7, 136, 136);
            padding: 20px 40px;
            border-radius: 100px;
            color: #fff;
            width: 80%;
            font-weight: bold;
            text-align: center;
            cursor: pointer;

            &.emptyGrade{
                background-color: #eee;
            }

        }
  
    }



.gradingPart{

    width: 100%;
    background-color: transparent;
    box-shadow: none !important;
    margin: 0;
    padding: 0px;

    .bleet{

        .deleteCone{
            background-color: blue !important;
            display: none !important;
        }

    }

    table td {

        vertical-align: top;

        input{


        margin: 0 !important;


        }
    }

    td textarea{
        font-size: 14px;
        line-height: 24px;
        margin: 0px 0 !important;
        padding: 10px 20px !important;
        background-color: #fff;
    }

    td{

        input{
            background-color: #fff;
        }
    }

    textarea{
        font-size: 16px;
        color: #000;
        line-height: 28px;
        padding: 20px;
        border-radius: 8px;
    }

    .line-section{
        opacity: .5;
    }

    .section{
        
        label{
            font-size: 21px;
            color: #000;
        }
    }

    .grade-value{
        border-color: whitesmoke;
        background-color: transparent;
        padding: 10px;
        border-radius: 0px;
        font-size: 12px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid whitesmoke;
        padding-top: 30px;

        label{
            color: #fff;
            width: max-content;
            color: #000;
            font-size: 12px;
        }

        input{
            color: #000;
            border: 1px solid rgba($color: #fff, $alpha: 0.3) !important;
            padding: 20px 20px;
            border-radius: 8px;
            width: 125px;
            margin: 0 !important;
        }
    }
}

.council-wrap{
    padding : 0px 0 !important;
}

.showSheet{
    display: block !important;
}

.action{
    background-color: #f60 !important;
    color: #fff;
    font-weight: bold;
}

.realtimeScore{
    display: none;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 30px;
    position: fixed;
    background-color: #fff7ea;
    background-color: #000;
    color: #fff;
    bottom: 0;
    left: 0;
    padding: 10px 12%;

    .submitApplication{
        margin: 0;
        padding: 15px 20px;
        border-radius: 8px;
        background-color: #ff9d00;

        &.emppty{
            background-color: rgb(27, 27, 27);
            color: #777;
        }
    }

    
}


.loaderScreen{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba($color: #fff, $alpha: 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sub-section a{
    display: block;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;

    &:hover{
        color: #00c9aa;
    }
}

.sub-section input{
    margin-top: 15px !important;
}

.councilBGArea{
    background-color: rgb(250, 250, 250);
}

.gradeHere{
    width: 30%;
    height: 100vh;
    background-color: #00c9aa;
    display: none;
}



.viewVideo{
    background-color: #7600ff;
    color: #fff;
    padding: 18px 20px;
    width: max-content;
    font-size: 12px;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    
}

}
.showModalComplete{

    background-color: rgba(255, 255, 255, 0.95);
    background-color: rgba(51, 51, 51, 0.9);
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 9999;

    .closeBack{
        height: 100vh;
        width: 100%;
    }

    .thereal{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        width: 450px;
        position: absolute;
        z-index: 9999999999999999;
        left: 0;
        top: 0 ;

        .imageProcess{

            overflow: hidden;
            width: 100%;
            height: 400px;
            background-color: #000;
        }

        h1{
            letter-spacing: -2px;
            margin: 0px 0;
            font-size: 28px;
            line-height: 33px;
            margin-bottom: 30px;
        }

        h4{
            letter-spacing: -.5px;
            margin-bottom: 20px;
            color: #555;
        }
    
        .contentSubmit{
            display: flex;
            align-items: center;
            flex-direction: column;
            width:100%;
            text-align: center;
            padding: 30px;
    
            p{
                font-size: 13px;
                line-height: 22px;
                margin-top: 0px;
                color: #444;
            }
    
            
        }
    
        .goDashboard{
            margin-top: 50px;
            background-color: #000;
            font-size: 13px;
            padding: 20px 25px;
            color: #fff;
            font-weight: bold;
            border-radius: 0px;
            margin-top: auto;
            margin-bottom: 30px;
            width: calc(100% - 60px);
            text-align: center;
            cursor: pointer;
    
        }
    }

    

}


@media (max-width: 1024px) {


    .showModalComplete{

        background-color: rgba(255, 255, 255, 0.95);
        background-color: rgba(51, 51, 51, 0.9);
        height: 100vh;
        width: 100%;
        position: fixed;
        z-index: 9999;
    
        .thereal{
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100vh;
            width: 450px;
            animation: slideIn .1s ease-in-out;
    
            .imageProcess{
    
                overflow: hidden;
                width: 100%;
                height: 400px;
                background-color: #000;
            }
    
            h1{
                letter-spacing: -2px;
                margin: 0px 0;
                font-size: 28px;
                line-height: 33px;
                margin-bottom: 30px;
            }
    
            h4{
                letter-spacing: -.5px;
                margin-bottom: 20px;
                color: #555;
            }
        
            .contentSubmit{
                display: flex;
                align-items: center;
                flex-direction: column;
                width:100%;
                text-align: center;
                padding: 30px;
        
                p{
                    font-size: 13px;
                    line-height: 22px;
                    margin-top: 0px;
                    color: #444;
                }
        
                
            }
        
            .goDashboard{
                margin-top: 50px;
                background-color: #000;
                font-size: 13px;
                padding: 20px 25px;
                color: #fff;
                font-weight: bold;
                border-radius: 0px;
                margin-top: auto;
                margin-bottom: 30px;
                width: calc(100% - 60px);
                text-align: center;
        
            }
        }
    
        
    
    }



}

@media (max-width: 425px) {


    .showModalComplete{

        background-color: rgba(255, 255, 255, 0.95);
        background-color: rgba(51, 51, 51, 0.9);
        height: 100vh;
        width: 100%;
        position: fixed;
        z-index: 9999999999;
    
        .thereal{
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100vh;
            width: 100%;
            animation: none;
    
            .imageProcess{
    
                overflow: hidden;
                width: 100%;
                height: 400px;
                background-color: #000;
            }
    
            h1{
                letter-spacing: -1px;
                margin: 0px 0;
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 30px;
            }
    
            h4{
                letter-spacing: -1px;
                margin-bottom: 20px;
                color: #555;
                font-size: 14px;
            }
        
            .contentSubmit{
                display: flex;
                align-items: center;
                flex-direction: column;
                width:100%;
                text-align: center;
                padding: 20px;
        
                p{
                    font-size: 13px;
                    line-height: 22px;
                    margin-top: 0px;
                    color: #444;
                }
        
                
            }
        
            .goDashboard{
                margin-top: 50px;
                background-color: #000;
                font-size: 13px;
                padding: 20px 25px;
                color: #fff;
                font-weight: bold;
                border-radius: 0px;
                margin-top: auto;
                margin-bottom: 20px;
                width: calc(100% - 40px);
                text-align: center;
        
            }
        }
    
        
    
    }



}

@keyframes slideIn {

    from {
        margin-left: -500px;
    }

    to{
        margin-left: 0;
    }

}



